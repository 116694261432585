<script setup>
const props = defineProps({
  label       : { type: String, default: null },
  labelFor    : { type: String, default: null },
  required    : { type: Boolean, default: false },
  message     : { type: [Array, String], default: '' },
  errorMessage: { type: [Array, String], default: '' },
})

const messages = computed(() => {
  if (Array.isArray(props.message)) {
    return props.message.filter(v => v).join('<br>')
  } else if (typeof props.message == 'string') {
    return props.message.split('\n').filter(v => v).join('<br>')
  } else {
    return ''
  }
})

const errorMessages = computed(() => {
  if (Array.isArray(props.errorMessage)) {
    return props.errorMessage.filter(v => v).join('<br>')
  } else if (typeof props.errorMessage == 'string') {
    return props.errorMessage.split('\n').filter(v => v).join('<br>')
  } else {
    return ''
  }
})
</script>


<template>
  <fieldset class="flex flex-col">
    <label
      v-if="label"
      class="disabled:cursor-not-allowed mb-1 font-noto-serif"
      :class="labelFor ? 'cursor-pointer': 'cursor-text'"
      :for="labelFor"
    >
      {{ label }}
      <span
        v-if="required"
        color="error"
        class="badge badge-sm badge-primary !px-2 ml-3 font-noto-serif"
      >必須</span>
    </label>

    <slot></slot>

    <span
      v-if="!errorMessage && messages"
      v-html="messages"
      class="text-zinc text-sm"
    ></span>
    <span
      v-if="errorMessage"
      v-html="errorMessages"
      class="text-red text-sm"
    ></span>
  </fieldset>
</template>